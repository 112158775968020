export const API_URL =
  process.env.NODE_ENV === 'development' ? 'https://localhost:5001' : '/api';

export const APP_ROUTE = {
  ROOT: '/',
  LOGIN: '/login',
  HOME: '/home',
  QUOTES: '/quotes',
  QUOTE_CREATION: '/quotes/new',
  QUOTE_DETAIL: '/quotes/detail',
  ESCALATIONS: '/review',
  ESCALATIONS_DETAIL: '/review/:clientId/:quoteId',
  REPORTING: '/reporting',
  USER_ADMINISTRATION: '/user-administration',
  PRICING_SHEETS: '/pricing-sheets',
  PRICING_SHEET_UPLOAD: '/pricing-sheets/new',
  PRICING_SHEET_EDIT: '/pricing-sheets/:pricingSheetId',
  RESET_PASSWORD: '/reset-password',
  CREATE_PASSWORD: '/create-password',
  NOT_FOUND_ERROR: '/404',
  SERVER_ERROR: '/500',
};
