import { CREATE_USER, GET_USERS, UPDATE_USER } from '../endpoints';
import axios from 'axios';

/**
 * Create user
 */
export const createUser = (header, userData) => {
  return axios.post(CREATE_USER, userData, header);
};

/**
 * Get All Users
 */
export const getUsers = (header) => {
  return axios.get(GET_USERS, header);
};

/**
 * Update a User
 */
export const updateUser = (header, userData) => {
  return axios.put(`${UPDATE_USER}/${userData.id}`, userData, header);
};
