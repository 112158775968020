import { common, grey } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core';

export const baseMuiTheme = createMuiTheme({
  typography: {
    fontFamily: '"Source Sans Pro", "Helvetica", "Arial", sans-serif',
    fontSize: 18,
    fontWeight: 400,
    lineHeight: '24.75px',
    h1: {
      fontSize: 32,
      fontWeight: 400,
      lineHeight: 1.25,
    },
    h2: {
      fontSize: 27,
      fontWeight: 400,
      lineHeight: 1.25,
    },
    h3: {
      fontSize: 24,
      fontWeight: 400,
      lineHeight: 1.25,
    },
    h4: {
      fontSize: 18,
      fontWeight: 400,
      lineHeight: '20.25px',
    },
    h5: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '18px',
    },
    h6: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '15.75px',
    },
    body1: {
      fontSize: 18,
      fontWeight: 400,
      lineHeight: '24.75px',
    },
    body2: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '22px',
    },
    button: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '18px',
      textTransform: 'none',
    },
    caption: {
      fontSize: 12,
      lineHeight: '15px',
    },
  },
  palette: {
    primary: {
      main: '#BA0C2F',
    },
    secondary: {
      main: '#838271',
    },
    success: {
      main: '#007E7F',
    },
    error: {
      main: '#EA0F3B',
    },
    warning: {
      main: '#B48B25',
    },
    background: {
      paper: common.white,
    },
    text: {
      primary: '#51534A',
      secondary: '#51534A',
    },
    divider: '#EEEEED',
    lightDivider: '#F7F7F7',
    errorDarker: '#BA0C2F',
    contrastThreshold: 2,
  },
  shape: {
    borderRadius: 0,
  },
  overrides: {
    MuiDialogActions: {
      root: {
        padding: '8px 24px 24px',
      },
    },
    MuiFormControl: {
      root: {
        marginBottom: 12,
      },
    },
    Box: {
      root: {
        padding: 48,
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: 12,
      },
      notchedOutline: {
        borderWidth: '1px !important',
        borderColor: '#51534A',
      },
    },
    MuiButton: {
      root: {
        '& .MuiSvgIcon-root': {
          marginLeft: 16,
        },
      },
    },
    MuiTableCell: {
      root: {
        fontSize: 14,
        borderBottom: `1px solid #EEEEED`,
      },
      head: {
        fontSize: 16,
        fontWeight: 600,
        color: 'inherit',
      },
      body: {
        color: 'inherit',
      },
    },
    MuiSelect: {
      select: {
        '&.MuiSelect-select': {
          paddingRight: 36,
        },
        '& + .MuiSelect-icon': {
          top: 'calc(50% - 16px)',
        },
      },
    },
    MuiInputBase: {
      root: {
        '&$disabled': {
          backgroundColor: '#EEEEED',
          color: '#51534A',
        },
      },
    },
  },
});
