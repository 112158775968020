export const USER_ROLES = {
  HEAD_OFFICE: 'headoffice',
  BROKER: 'broker',
  PRICING_TEAM: 'pricing',
};

export const GENDER = [
  {
    value: 1,
    label: 'Male',
    stringValue: 'male',
  },
  {
    value: 2,
    label: 'Female',
    stringValue: 'female',
  },
];
