import { createSlice } from '@reduxjs/toolkit';
import { getRequestHeader } from '../../utils';
import moment from 'moment';
import { USER_ROLES } from '../../constants/user';

/**
 * QuotesSlice: a slice that manages all quotes
 */

export const initialState = {
  isLoading: false,
  isUploading: false,
  isDeleting: false,
  pricingSheets: [],
  error: null,
};

export const pricingSheetsSlice = createSlice({
  name: 'pricingSheets',
  initialState,
  reducers: {
    getPricingSheets: (state) => {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    },
    getPricingSheetsSuccess: (state, action) => {
      let activeAssigned = false;
      const pricingSheets = action.payload;
      const systemPricingSheets = pricingSheets
        .filter((sheet) => sheet.isSystem)
        .sort((a, b) => (moment(a.startAt).isBefore(b.startAt) ? 1 : -1))
        .map((sheet, index) => {
          let isActive = false;
          if (!activeAssigned && moment(sheet.startAt).isBefore()) {
            activeAssigned = true;
            isActive = true;
          }
          return { ...sheet, isActive };
        });
      const sandboxPricingSheets = pricingSheets.filter(
        (sheet) => !sheet.isSystem
      );
      return {
        ...state,
        isLoading: false,
        pricingSheets: [...systemPricingSheets, ...sandboxPricingSheets],
        error: null,
      };
    },
    getPricingSheetsFailed: (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
    uploadPricingSheet: (state) => {
      return {
        ...state,
        isUploading: true,
        error: null,
      };
    },
    uploadPricingSheetSuccess: (state, action) => {
      return {
        ...state,
        isUploading: false,
        error: null,
      };
    },
    uploadPricingSheetFailed: (state, action) => {
      return {
        ...state,
        isUploading: false,
        error: action.payload,
      };
    },
    updatePricingSheet: (state) => {
      return { ...state, isLoading: true };
    },
    updatePricingSheetSuccess: (state, action) => {
      return {
        ...state,
        isUploading: false,
        error: null,
      };
    },
    updatePricingSheetFailed: (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
    deletePricingSheet: (state) => {
      return { ...state, isDeleting: true };
    },
    deletePricingSheetSuccess: (state, action) => {
      return {
        ...state,
        isDeleting: false,
        error: null,
      };
    },
    deletePricingSheetFailed: (state, action) => {
      return {
        ...state,
        isDeleting: false,
        error: action.payload,
      };
    },
  },
});

const {
  getPricingSheets,
  getPricingSheetsSuccess,
  getPricingSheetsFailed,
  uploadPricingSheet,
  uploadPricingSheetSuccess,
  uploadPricingSheetFailed,
  updatePricingSheet,
  updatePricingSheetSuccess,
  updatePricingSheetFailed,
  deletePricingSheet,
  deletePricingSheetSuccess,
  deletePricingSheetFailed,
} = pricingSheetsSlice.actions;

export default pricingSheetsSlice.reducer;

/**
 * Get All Pricing Sheets
 */
export const getPricingSheetsAsync = (role) => async (
  dispatch,
  getState,
  api
) => {
  try {
    dispatch(getPricingSheets());
    if (role !== USER_ROLES.BROKER) {
      const response = await api.getPricingSheets(getRequestHeader(true));
      if (response.status === 200) {
        dispatch(getPricingSheetsSuccess(response.data));
      }
    } else {
      const response = await api.getActivePricingSheets(getRequestHeader());
      if (response.status === 200) {
        dispatch(getPricingSheetsSuccess([response.data]));
      }
    }
  } catch (error) {
    dispatch(getPricingSheetsFailed(error));
  }
};

/**
 * Upload a Pricing Sheets
 */
export const uploadPricingSheetAsync = (payload) => async (
  dispatch,
  getState,
  api
) => {
  try {
    dispatch(uploadPricingSheet());
    const response = await api.uploadPricingSheet(
      getRequestHeader(true),
      payload
    );
    if (response.status === 200) {
      dispatch(uploadPricingSheetSuccess(response.data));
    }
  } catch (error) {
    dispatch(uploadPricingSheetFailed(error));
  }
};

/**
 * Edit a Pricing Sheet
 */
export const editPricingSheetAsync = (payload, id) => async (
  dispatch,
  getState,
  api
) => {
  try {
    dispatch(updatePricingSheet());
    const response = await api.editPricingSheet(
      getRequestHeader(false, true),
      payload,
      id
    );
    if (response.status === 200) {
      dispatch(updatePricingSheetSuccess(response.data));
    }
  } catch (error) {
    dispatch(updatePricingSheetFailed(error));
  }
};

/**
 * Delete a Pricing Sheet
 */
export const deletePricingSheetAsync = (id) => async (
  dispatch,
  getState,
  api
) => {
  try {
    dispatch(deletePricingSheet());
    const response = await api.deletePricingSheet(
      getRequestHeader(false, true),
      id
    );
    if (response.status === 200) {
      dispatch(deletePricingSheetSuccess(response.data));
    }
  } catch (error) {
    dispatch(deletePricingSheetFailed(error));
  }
};

// Selectors
export const pricingSheetsSelector = (state) => state.pricingSheetsState;
