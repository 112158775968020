import { createSlice } from '@reduxjs/toolkit';
import { getRequestHeader } from '../../utils';
import {
  getCookieValue,
  removeCookie,
  setCookieValue,
} from '../../utils/cookie';

/**
 * UserSlice: a slice that manages logged in users
 */

const token = getCookieValue('clbpToken');

export const initialState = {
  isLoading: false,
  user: null,
  authStep: null, // EnrollInMfa | SubmitOtp | Authenticated | EnterCredential
  email: null,
  token: token || null,
  error: null,
  loginError: null,
  otpCode: null,
  otpExpiresIn: null,
  mfaError: null,
  dataInitializing: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getUser: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    getUserSuccess: (state, action) => {
      state.isLoading = false;
      state.user = action.payload;
      state.error = null;
      state.dataInitializing = true;
    },
    getUserFailed: (state, action) => {
      state.isLoading = false;
      state.user = null;
      state.error = action.payload;
    },
    authUser: (state, action) => {
      state.isLoading = true;
      state.loginError = null;
      state.email = action.payload;
    },
    authUserSuccess: (state) => {
      state.isLoading = false;
      state.loginError = null;
    },
    authUserFailed: (state, action) => {
      state.isLoading = false;
      state.token = null;
      state.loginError = 'error';
    },
    userLogout: (state) => {
      return {
        state,
      };
    },
    userLogoutSuccess: (state) => {
      return {
        ...initialState,
      };
    },
    userLogoutFailed: (state) => {
      return {
        state,
      };
    },
    userPasswordResetRequest: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    userPasswordResetRequestSuccess: (state) => {
      state.isLoading = false;
      state.error = null;
    },
    userPasswordResetRequestFailed: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    userSavePassword: (state) => {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    },
    userSavePasswordSuccess: (state) => {
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    },
    userSavePasswordFailed: (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
    updateUser: (state) => {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    },
    updateUserSuccess: (state, action) => {
      const updatedUser = action.payload;
      return {
        ...state,
        isLoading: false,
        user: updatedUser,
      };
    },
    updateUserFailed: (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: action.payload.response.data,
      };
    },
    updateToken: (state, action) => {
      state.token = action.payload;
    },
    updateAuthStep: (state, action) => {
      state.isLoading = false;
      state.authStep = action.payload;
    },
    resetAuthStep: (state) => {
      state.authStep = null;
    },
    updateMfa: (state) => {
      state.isLoading = true;
      state.mfaError = null;
    },
    updateMfaFailed: (state, action) => {
      state.isLoading = false;
      state.mfaError = action.payload;
    },
    updateConsentToMfaSuccess: (state, action) => {
      const { authStep, otpExpiresIn, token } = action.payload;
      return {
        ...state,
        isLoading: false,
        mfaError: null,
        authStep,
        otpExpiresIn,
        token,
      };
    },
    updateResendOtpSuccess: (state, action) => {
      const { authStep, otpExpiresIn } = action.payload;
      return {
        ...state,
        isLoading: false,
        mfaError: null,
        authStep,
        otpExpiresIn,
      };
    },
    updateSubmitOtpSuccess: (state, action) => {
      const { authStep, otpExpiresIn } = action.payload;
      return {
        ...state,
        isLoading: false,
        mfaError: null,
        authStep,
        otpExpiresIn,
      };
    },
    completeInitialization: (state) => {
      return {
        ...state,
        dataInitializing: false,
      };
    },
  },
});

export const {
  getUser,
  getUserSuccess,
  getUserFailed,
  authUser,
  authUserSuccess,
  authUserFailed,
  userPasswordResetRequest,
  userPasswordResetRequestSuccess,
  userPasswordResetRequestFailed,
  userSavePassword,
  userSavePasswordSuccess,
  userSavePasswordFailed,
  updateUser,
  updateUserSuccess,
  updateUserFailed,
  userLogout,
  userLogoutSuccess,
  userLogoutFailed,
  updateToken,
  updateAuthStep,
  resetAuthStep,
  updateMfa,
  updateMfaFailed,
  updateConsentToMfaSuccess,
  updateResendOtpSuccess,
  updateSubmitOtpSuccess,
  completeInitialization,
} = userSlice.actions;

export default userSlice.reducer;

/**
 * Get information of logged in user
 */
export const getUserAsync = () => async (dispatch, getState, api) => {
  try {
    dispatch(getUser());
    const response = await api.getUser(getRequestHeader());
    if (response.status === 200) {
      dispatch(getUserSuccess(response.data));
    }
  } catch (error) {
    dispatch(getUserFailed(error));
  }
};

/**
 * Authenticate user
 */
export const authUserAsync = (data) => async (dispatch, getState, api) => {
  try {
    dispatch(authUser(data.emailAddress));
    const response = await api.authUser(getRequestHeader(), data);
    if (response.status === 200) {
      const { authStep, token } = response.data;
      dispatch(updateAuthStep(authStep));
      dispatch(updateToken(token));
      if (authStep === 'Authenticated') {
        dispatch(authUserSuccess());
      }
    }
  } catch (error) {
    dispatch(authUserFailed(error));
  }
};

/**
 * User Logout
 */
export const userLogoutAsync = (data) => async (dispatch, getState, api) => {
  try {
    dispatch(userLogout());
    const response = await api.userLogout(getRequestHeader(), data);
    if (response.status === 200) {
      dispatch(userLogoutSuccess());
    }
  } catch (error) {
    dispatch(userLogoutFailed(error));
  }
};

/**
 * Request for password reset email
 */
export const requestPasswordResetAsync = (data) => async (
  dispatch,
  getState,
  api
) => {
  try {
    dispatch(userPasswordResetRequest());
    const response = await api.requestUserPasswordReset(
      getRequestHeader(),
      data
    );
    if (response.status === 200) {
      dispatch(userPasswordResetRequestSuccess());
    }
  } catch (error) {
    dispatch(userPasswordResetRequestFailed(error));
  }
};

/**
 * Save password
 */
export const savePasswordAsync = (data) => async (dispatch, getState, api) => {
  try {
    dispatch(userSavePassword());
    const response = await api.savePassword(getRequestHeader(), data);
    if (response.status === 200) {
      dispatch(userSavePasswordSuccess());
    }
  } catch (error) {
    dispatch(userSavePasswordFailed(error));
  }
};

/**
 * Update user
 */
export const updateUserAsync = (data) => async (dispatch, getState, api) => {
  try {
    dispatch(updateUser());
    const response = await api.updateUser(getRequestHeader(), data);
    if (response.status === 200) {
      dispatch(updateUserSuccess(response.data));
    }
  } catch (error) {
    dispatch(updateUserFailed(error));
  }
};

/**
 * Consent MFA
 */
export const requestConsentToMfaAsync = (data) => async (
  dispatch,
  getState,
  api
) => {
  try {
    dispatch(updateMfa());
    const response = await api.consentToMfa(getRequestHeader(), data);
    if (response.status === 200) {
      dispatch(updateConsentToMfaSuccess(response.data));
    }
  } catch (error) {
    dispatch(updateMfaFailed(error));
  }
};

/**
 * Resend OTP
 */
export const requestResendOtpAsync = () => async (dispatch, getState, api) => {
  try {
    const response = await api.resendOtp(getRequestHeader());
    if (response.status === 200) {
      dispatch(updateResendOtpSuccess(response.data));
    }
  } catch (error) {
    dispatch(updateMfaFailed(error));
  }
};

/**
 * Submit OTP
 */
export const requestSubmitOtpAsync = (data) => async (
  dispatch,
  getState,
  api
) => {
  try {
    dispatch(updateMfa());
    const response = await api.submitOtp(getRequestHeader(), data);
    if (response.status === 200) {
      const { authStep } = response.data;
      dispatch(updateAuthStep(authStep));
      if (authStep === 'Authenticated') {
        dispatch(authUserSuccess());
      }
    }
  } catch (error) {
    dispatch(updateMfaFailed(error));
  }
};

// Selectors
export const userSelector = (state) => state.userState;
