import Cookies from 'js-cookie';

/**
 * Find the value of name passed in param from cookie
 * and return it
 * @param name The name of cookie want to get the value
 */
export const getCookieValue = (name) => {
  return Cookies.get(name);
};

/**
 * Create a cookie
 * @param name name of data
 * @param value value
 */
export const setCookieValue = (name, value) => {
  Cookies.set(name, value);
};

/**
 * Remove cookie
 * @param name The name of cookie want to remove
 */
export const removeCookie = (name) => {
  Cookies.remove(name);
};

/**
 * Find the value of header
 * and return it
 */
export const getRequestHeader = (needFormDataHeader, needJsonPatchHeader) => {
  if (needFormDataHeader) {
    return {
      headers: {
        'Content-Type': 'multipart/form-data',
        // Authorization: `Bearer ${getCookieValue('clbpToken')}`,
      },
    };
  } else if (needJsonPatchHeader) {
    return {
      headers: {
        'Content-Type': 'application/json-patch+json',
        // Authorization: `Bearer ${getCookieValue('clbpToken')}`,
      },
    };
  }

  return {
    headers: {
      // Authorization: `Bearer ${getCookieValue('clbpToken')}`,
    },
  };
};
