export const mergeObjects = (arr, excludingKeys = []) => {
  // Initialize an empty object to hold the merged result
  const mergedObject = {};
  Object.keys(arr[0]).forEach((key) => {
    if (excludingKeys.includes(key)) {
      mergedObject[key] = arr.map((obj) => obj[key] || '').join(',');
    } else {
      mergedObject[key] = arr[0][key];
    }
  });
  return mergedObject;
};
