import {
  CREATE_QUOTE,
  GET_QUOTES,
  UPDATE_QUOTE,
  GET_QUOTES_BY_SEQUENCE,
  DOWNLOAD_QUOTE,
  GET_ESCALATED_QUOTES,
  REVIEW_QUOTE,
} from '../endpoints';
import axios from 'axios';
import { getRequestHeader } from '../../utils/cookie';

/**
 * Get All Quotes
 */
export const getQuotes = (header) => {
  return axios.get(GET_QUOTES, header);
};

/**
 * Get Quotes by Sequence
 */
export const getQuotesBySequence = (header, sequenceId) => {
  return axios.get(`${GET_QUOTES_BY_SEQUENCE}/${sequenceId}`, header);
};

/**
 * Get Escalated Quotes
 */
export const getEscalatedQuotes = (header) => {
  return axios.get(`${GET_ESCALATED_QUOTES}`, header);
};

/**
 * Get A Quote
 */
export const createQuote = (header, data) => {
  return axios.post(CREATE_QUOTE, data, header);
};

/**
 * Update A Quote
 */
export const updateQuote = (header, data) => {
  return axios.post(UPDATE_QUOTE, data, header);
};

/**
 * Review A Quote
 */
export const reviewQuote = (header, data) => {
  return axios.put(REVIEW_QUOTE, data, header);
};

/**
 * Download A Quote
 */
export const downloadQuote = (quoteId) => {
  return axios.get(`${DOWNLOAD_QUOTE}/${quoteId}`, {
    ...getRequestHeader(),
    responseType: 'blob',
  });
};
