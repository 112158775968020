import React, { Suspense, lazy, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import { APP_ROUTE } from '../constants/url';
import {
  getPricingSheetsAsync,
  pricingSheetsSelector,
} from '../slices/pricingSheets';
import { getQuotesAsync } from '../slices/quotes';
import {
  completeInitialization,
  getUserAsync,
  userSelector,
} from '../slices/user';

/** Do not alter import declaration.
 * webpackPrefetch/webpackPreload: magic string to help webpack optimization
 * https://webpack.js.org/guides/code-splitting/#prefetchingpreloading-modules
 * webpackChunkName: helps naming code-split chunk
 * Also DO NOT put import() within lazy() as this will break preload/prefetch
 */
const HomePage = import(
  /* webpackPrefetch: true, webpackChunkName: "HomePage" */ '../pages/HomePage'
);
const LazyHomePage = lazy(() => HomePage);

const NotFoundPage = import(
  /* webpackPrefetch: true, webpackChunkName: "NotFoundPage" */ '../pages/NotFoundPage'
);
const LazyNotFoundPage = lazy(() => NotFoundPage);

const LoginPage = import(
  /* webpackPrefetch: true, webpackChunkName: "LoginPage" */ '../pages/LoginPage'
);
const LazyLoginPage = lazy(() => LoginPage);

const PasswordResetPage = import(
  /* webpackPrefetch: true, webpackChunkName: "PasswordResetPage" */ '../pages/PasswordResetPage'
);
const LazyPasswordResetPage = lazy(() => PasswordResetPage);

const UserInvitationPage = import(
  /* webpackPrefetch: true, webpackChunkName: "UserInvitationPage" */ '../pages/UserInvitationPage'
);
const LazyUserInvitationPage = lazy(() => UserInvitationPage);

const UserAdministrationPage = import(
  /* webpackPrefetch: true, webpackChunkName: "UserAdministrationPage" */ '../pages/UserAdministrationPage'
);
const LazyUserAdministrationPage = lazy(() => UserAdministrationPage);

const PricingSheetPage = import(
  /* webpackPrefetch: true, webpackChunkName: "PricingSheetPage" */ '../pages/PricingSheetPage'
);
const LazyPricingSheetPage = lazy(() => PricingSheetPage);

const PricingSheetUploadPage = import(
  /* webpackPrefetch: true, webpackChunkName: "PricingSheetUploadPage" */ '../pages/PricingSheetUploadPage'
);
const LazyPricingSheetUploadPage = lazy(() => PricingSheetUploadPage);

const LazyPricingSheetEditPage = lazy(() => PricingSheetUploadPage);

const QuotesPage = import(
  /* webpackPrefetch: true, webpackChunkName: "QuotesPage" */ '../pages/QuotesPage'
);
const LazyQuotesPage = lazy(() => QuotesPage);

const QuoteCreationPage = import(
  /* webpackPrefetch: true, webpackChunkName: "QuoteCreationPage" */ '../pages/QuoteCreationPage'
);
const LazyQuoteCreationPage = lazy(() => QuoteCreationPage);

const QuoteDetailPage = import(
  /* webpackPrefetch: true, webpackChunkName: "QuoteCreationPage" */ '../pages/QuoteDetailPage'
);
const LazyQuoteDetailPage = lazy(() => QuoteDetailPage);

const ReportingPage = import(
  /* webpackPrefetch: true, webpackChunkName: "ReportingPage" */ '../pages/ReportingPage'
);
const LazyReportingPage = lazy(() => ReportingPage);

const EscalationsPage = import(
  /* webpackPrefetch: true, webpackChunkName: "ReportingPage" */ '../pages/EscalationsPage'
);
const LazyEscalationsPage = lazy(() => EscalationsPage);

const EscalationsQuotesPage = import(
  /* webpackPrefetch: true, webpackChunkName: "ReportingPage" */ '../pages/EscalatedQuotePage'
);
const LazyEscalatedQuotePage = lazy(() => EscalationsQuotesPage);

const AuthRoute = ({ component: Component, loggedIn, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      return loggedIn ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      );
    }}
  />
);

const App = () => {
  const { formatMessage } = useIntl();
  const userState = useSelector(userSelector);
  const pricingSheetState = useSelector(pricingSheetsSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserAsync());
  }, []);

  useEffect(() => {
    if (userState.authStep === 'Authenticated' || userState.token) {
      dispatch(getUserAsync());
    }
  }, [userState.authStep, userState.token]);

  useEffect(() => {
    if (userState.user && userState.dataInitializing) {
      dispatch(getPricingSheetsAsync(userState.user.role));
      dispatch(getQuotesAsync());
    }
  }, [userState.user, userState.dataInitializing]);

  useEffect(() => {
    if (pricingSheetState.pricingSheets.length) {
      dispatch(completeInitialization());
    }
  }, [pricingSheetState.pricingSheets]);

  return (
    <Suspense fallback={<div>{formatMessage({ id: 'common.isLoading' })}</div>}>
      <Router>
        <Switch>
          <Route path={APP_ROUTE.LOGIN} exact component={LazyLoginPage} />
          <Route
            path={APP_ROUTE.RESET_PASSWORD}
            exact
            component={LazyPasswordResetPage}
          />
          <Route
            path={APP_ROUTE.CREATE_PASSWORD}
            exact
            component={LazyUserInvitationPage}
          />
          <AuthRoute
            path={APP_ROUTE.ROOT}
            exact
            component={LazyQuotesPage}
            loggedIn={userState.user}
          />
          <AuthRoute
            path={`${APP_ROUTE.QUOTE_CREATION}/:clientId?/:sequenceId?`}
            component={LazyQuoteCreationPage}
            loggedIn={userState.user}
          />
          <AuthRoute
            path={`${APP_ROUTE.QUOTE_DETAIL}/:clientId/:sequenceId`}
            component={LazyQuoteDetailPage}
            loggedIn={userState.user}
          />
          <AuthRoute
            path={APP_ROUTE.QUOTES}
            component={LazyQuotesPage}
            loggedIn={userState.user}
          />
          <AuthRoute
            path={APP_ROUTE.REPORTING}
            component={LazyReportingPage}
            loggedIn={userState.user}
          />
          <AuthRoute
            exact
            path={APP_ROUTE.PRICING_SHEET_UPLOAD}
            component={LazyPricingSheetUploadPage}
            loggedIn={userState.user}
          />
          <AuthRoute
            path={APP_ROUTE.PRICING_SHEET_EDIT}
            component={LazyPricingSheetEditPage}
            loggedIn={userState.user}
          />
          <AuthRoute
            path={APP_ROUTE.PRICING_SHEETS}
            component={LazyPricingSheetPage}
            loggedIn={userState.user}
          />
          <AuthRoute
            path={APP_ROUTE.USER_ADMINISTRATION}
            component={LazyUserAdministrationPage}
            loggedIn={userState.user}
          />
          <AuthRoute
            path={APP_ROUTE.ESCALATIONS_DETAIL}
            component={LazyEscalatedQuotePage}
            loggedIn={userState.user}
          />
          <AuthRoute
            path={`${APP_ROUTE.ESCALATIONS}`}
            component={LazyEscalationsPage}
            loggedIn={userState.user}
          />
          <AuthRoute
            default={true}
            component={LazyNotFoundPage}
            loggedIn={userState.user}
          />
        </Switch>
      </Router>
    </Suspense>
  );
};

export default App;
