import { createSlice } from '@reduxjs/toolkit';
import { getRequestHeader } from '../../utils';

/**
 * ReportsSlice: a slice that manages all reports
 */

export const initialState = {
  isLoading: false,
  totalCount: 0,
  reports: [],
  companies: [],
  brokers: [],
  clients: [],
  error: null,
};

export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    getCompanies: (state) => {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    },
    getCompaniesSuccess: (state, action) => {
      state.isLoading = false;
      state.companies = action.payload;
      state.error = null;
    },
    getCompaniesFailed: (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: action.payload.response,
      };
    },
    getBrokers: (state) => {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    },
    getBrokersSuccess: (state, action) => {
      state.isLoading = false;
      state.brokers = action.payload;
      state.error = null;
    },
    getBrokersFailed: (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: action.payload.response,
      };
    },
    getClients: (state) => {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    },
    getClientsSuccess: (state, action) => {
      state.isLoading = false;
      state.clients = action.payload;
      state.error = null;
    },
    getClientsFailed: (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: action.payload.response,
      };
    },
    getReportsByParam: (state) => {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    },
    getReportsByParamSuccess: (state, action) => {
      state.isLoading = false;
      state.totalCount = action.payload.totalCount;
      state.reports = action.payload.reports;
      state.error = null;
    },
    getReportsByParamFailed: (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: action.payload.response,
      };
    },
  },
});

const {
  getCompanies,
  getCompaniesSuccess,
  getCompaniesFailed,
  getBrokers,
  getBrokersSuccess,
  getBrokersFailed,
  getClients,
  getClientsSuccess,
  getClientsFailed,
  getReportsByParam,
  getReportsByParamSuccess,
  getReportsByParamFailed,
} = reportsSlice.actions;

export default reportsSlice.reducer;

/**
 * Get all reports of logged in user
 */
export const getReportsAsync = (reportData) => async (
  dispatch,
  getState,
  api
) => {
  try {
    dispatch(getReportsByParam());
    const response = await api.getReportsByParam(
      getRequestHeader(true),
      reportData
    );
    if (response.status === 200) {
      dispatch(getReportsByParamSuccess(response.data));
    }
  } catch (error) {
    dispatch(getReportsByParamFailed(error));
  }
};

/**
 * Get Companies from string
 */
export const getCompaniesAsync = (companyData) => async (
  dispatch,
  getState,
  api
) => {
  try {
    dispatch(getCompanies());
    const response = await api.getCompanies(getRequestHeader(), companyData);
    if (response.status === 200) {
      dispatch(getCompaniesSuccess(response.data));
    }
  } catch (error) {
    dispatch(getCompaniesFailed(error));
  }
};

/**
 * Get Brokers from string
 */
export const getBrokersAsync = (brokerData) => async (
  dispatch,
  getState,
  api
) => {
  try {
    dispatch(getBrokers());
    const response = await api.getBrokers(getRequestHeader(), brokerData);
    if (response.status === 200) {
      dispatch(getBrokersSuccess(response.data));
    }
  } catch (error) {
    dispatch(getBrokersFailed(error));
  }
};

/**
 * Get Clients from string
 */
export const getClientsAsync = (clientData) => async (
  dispatch,
  getState,
  api
) => {
  try {
    dispatch(getClients());
    const response = await api.getClients(getRequestHeader(), clientData);
    if (response.status === 200) {
      dispatch(getClientsSuccess(response.data));
    }
  } catch (error) {
    dispatch(getClientsFailed(error));
  }
};

// Selectors
export const reportsSelector = (state) => state.reportsState;
