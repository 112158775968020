import {
  GET_REPORT_BY_COMPANY,
  GET_REPORT_BY_BROKERS,
  GET_REPORT_BY_CLIENTS,
  GET_REPORT_BY_PARAM,
  EXPORT_REPORT_BY_PARAM,
} from '../endpoints';
import axios from 'axios';
import { getCookieValue } from '../../utils/cookie';
import { getRequestHeader } from '../../utils';
import * as qs from 'qs';

/**
 * Get report by company
 */
export const getCompanies = (header, reportData) => {
  return axios.get(
    `${GET_REPORT_BY_COMPANY}/${reportData.companyNameSearch}`,
    header
  );
};

/**
 * Get report by broker
 */
export const getBrokers = (header, reportData) => {
  return axios.get(
    `${GET_REPORT_BY_BROKERS}/${reportData.preparedBySearch}`,
    header
  );
};

/**
 * Get report by clients
 */
export const getClients = (header, reportData) => {
  return axios.get(
    `${GET_REPORT_BY_CLIENTS}/${reportData.clientNameSearch}`,
    header
  );
};

/**
 * Get report by search params (multiple)
 */
export const getReportsByParam = (header, reportData) => {
  return axios.get(GET_REPORT_BY_PARAM, {
    params: {
      ...(reportData.company.length !== 0
        ? { CompanyNames: reportData.company }
        : {}),
      ...(reportData.preparedBy.length !== 0
        ? { PreparedByNames: reportData.preparedBy }
        : {}),
      ...(reportData.clientName.length !== 0
        ? { ClientNames: reportData.clientName }
        : {}),
      ...(reportData.issueStartDate
        ? { IssueStartDate: reportData.issueStartDate }
        : {}),
      ...(reportData.issueEndDate
        ? { IssueEndDate: reportData.issueEndDate }
        : {}),
      ...(reportData.quoteStatus.length !== 0
        ? { QuoteStatuses: reportData.quoteStatus }
        : {}),
      ...(reportData.quoteId.length !== 0
        ? { QuoteIds: reportData.quoteId }
        : {}),
      ...(reportData.pageSize ? { Limit: reportData.pageSize } : {}),
      ...(reportData.pageIndex
        ? { Offset: reportData.pageIndex * reportData.pageSize }
        : {}),
      ...(reportData.createdStartDate
        ? { CreatedStartDate: reportData.createdStartDate }
        : {}),
      ...(reportData.createdEndDate
        ? { CreatedEndDate: reportData.createdEndDate }
        : {}),
    },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
    header,
  });
};

export const exportReports = (reportData) => {
  return axios.get(EXPORT_REPORT_BY_PARAM, {
    params: {
      ...(reportData.company.length !== 0
        ? { CompanyNames: reportData.company }
        : {}),
      ...(reportData.preparedBy.length !== 0
        ? { PreparedByNames: reportData.preparedBy }
        : {}),
      ...(reportData.clientName.length !== 0
        ? { ClientNames: reportData.clientName }
        : {}),
      ...(reportData.issueStartDate
        ? { IssueStartDate: reportData.issueStartDate }
        : {}),
      ...(reportData.issueEndDate
        ? { IssueEndDate: reportData.issueEndDate }
        : {}),
      ...(reportData.quoteStatus.length !== 0
        ? { QuoteStatuses: reportData.quoteStatus }
        : {}),
      ...(reportData.quoteId.length !== 0
        ? { QuoteIds: reportData.quoteId }
        : {}),
      ...(reportData.createdStartDate
        ? { CreatedStartDate: reportData.createdStartDate }
        : {}),
      ...(reportData.createdEndDate
        ? { CreatedEndDate: reportData.createdEndDate }
        : {}),
      Consolidated: reportData.consolidated,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
    ...getRequestHeader(),
    responseType: 'blob',
  });
};
