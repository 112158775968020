import { createSlice } from '@reduxjs/toolkit';
import { getRequestHeader } from '../../utils';

export const initialState = {
  isLoading: false,
  isCreating: false,
  isDeleting: false,
  users: [],
  error: null,
};

/**
 * UsersSlice: a slice that manages all users on the platform
 */

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    getUsers: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    getUsersSuccess: (state, action) => {
      state.isLoading = false;
      state.users = action.payload;
      state.error = null;
    },
    getUsersFailed: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    createUser: (state) => {
      state.isCreating = true;
      state.error = null;
    },
    createUserSuccess: (state, action) => {
      return {
        ...state,
        isCreating: false,
        users: [...state.users, action.payload],
        error: null,
      };
    },
    createUserFailed: (state, action) => {
      return {
        ...state,
        isCreating: false,
        error: action.payload,
      };
    },
    updateUser: (state) => {
      return { ...state, isLoading: true };
    },
    updateUserSuccess: (state, action) => {
      const updatedUser = action.payload;
      const users = [...state.users];
      const index = state.users.findIndex((user) => user.id === updatedUser.id);
      users[index] = updatedUser;
      return {
        ...state,
        isLoading: false,
        users: users.filter((user) => !user.isdeleted),
        error: null,
      };
    },
    updateUserFailed: (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
    deleteUser: (state) => {
      return { ...state, isDeleting: true };
    },
    deleteUserSuccess: (state, action) => {
      const deletedUser = action.payload;
      const users = [...state.users];
      return {
        ...state,
        isDeleting: false,
        users: users.filter((user) => user.id !== deletedUser.id),
        error: null,
      };
    },
    deleteUserFailed: (state, action) => {
      return {
        ...state,
        isDeleting: false,
        error: action.payload,
      };
    },
  },
});

const {
  getUsers,
  getUsersSuccess,
  getUsersFailed,
  createUser,
  createUserSuccess,
  createUserFailed,
  updateUser,
  updateUserSuccess,
  updateUserFailed,
  deleteUser,
  deleteUserSuccess,
  deleteUserFailed,
} = usersSlice.actions;

export default usersSlice.reducer;

/**
 * Ger users
 */
export const getUsersAsync = () => async (dispatch, getState, api) => {
  try {
    dispatch(getUsers());
    const response = await api.getUsers(getRequestHeader());
    if (response.status === 200) {
      dispatch(getUsersSuccess(response.data));
    }
  } catch (error) {
    dispatch(getUsersFailed(error));
  }
};

/**
 * Create user
 */
export const createUserAsync = (user) => async (dispatch, getState, api) => {
  try {
    dispatch(createUser());
    const response = await api.createUser(getRequestHeader(), user);
    if (response.status === 200) {
      dispatch(createUserSuccess(response.data));
    }
  } catch (error) {
    dispatch(createUserFailed(error));
  }
};

/**
 * Update user
 */
export const updateUserAsync = (user) => async (dispatch, getState, api) => {
  try {
    dispatch(updateUser());
    const response = await api.updateUser(getRequestHeader(), user);
    if (response.status === 200) {
      dispatch(updateUserSuccess(user));
    }
  } catch (error) {
    dispatch(updateUserFailed('User update failed'));
  }
};

/**
 * Delete user
 */
export const deleteUserAsync = (user) => async (dispatch, getState, api) => {
  try {
    dispatch(deleteUser());
    const response = await api.deleteUser(getRequestHeader(), user);
    if (response.status === 200) {
      dispatch(deleteUserSuccess(user));
    }
  } catch (error) {
    dispatch(deleteUserFailed('User removal failed'));
  }
};

// Selectors
export const usersSelector = (state) => state.usersState;
