import {
  GET_ACTIVE_PRICING_SHEETS,
  GET_PRICING_SHEETS,
  DELETE_PRICING_SHEETS,
  UPLOAD_PRICING_SHEETS,
  EDIT_PRICING_SHEETS,
  DOWNLOAD_PRICING_SHEETS,
} from '../endpoints';
import axios from 'axios';
import { getRequestHeader } from '../../utils/cookie';

/**
 * Get All Pricing Sheet
 */
export const getPricingSheets = (headers) => {
  return axios.get(GET_PRICING_SHEETS, headers);
};

/**
 * Get active pricing sheet
 */
export const getActivePricingSheets = (headers) => {
  return axios.get(GET_ACTIVE_PRICING_SHEETS, headers);
};

/**
 * Delete a pricing sheet
 */
export const deletePricingSheet = (headers, pricingSheetId) => {
  return axios.delete(`${DELETE_PRICING_SHEETS}/${pricingSheetId}`, headers);
};

/**
 * Upload a pricing sheet
 */
export const uploadPricingSheet = (headers, payload) => {
  const formData = new FormData();
  formData.append('file', payload.file);
  formData.append('fileName', payload.fileName);
  formData.append('description', payload.description);
  if (payload.isSystem) {
    formData.append('startAt', payload.startDate);
  }
  formData.append('isSystem', payload.isSystem);
  return axios.post(UPLOAD_PRICING_SHEETS, formData, headers);
};

/**
 * Edit a pricing sheet
 */
export const editPricingSheet = (headers, payload, id) => {
  return axios.put(`${EDIT_PRICING_SHEETS}/${id}`, payload, headers);
};

/**
 * Download a pricing sheet
 */
export const downloadPricingSheet = (pricingSheetId) => {
  return axios.get(`${DOWNLOAD_PRICING_SHEETS}/${pricingSheetId}/download`, {
    ...getRequestHeader(),
    responseType: 'blob',
  });
};
