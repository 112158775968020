import { API_URL } from '../constants/url';

export const GET_USER = `${API_URL}/user`;
export const AUTH_USER = `${API_URL}/user/login`;
export const LOGOUT = `${API_URL}/user/logout`;
export const RESET_PASSWORD = `${API_URL}/user/resetPassword`;
export const CREATE_PASSWORD = `${API_URL}/user/createPassword`;

export const CREATE_USER = `${API_URL}/user/createUser`;
export const GET_USERS = `${API_URL}/users`;
export const UPDATE_USER = `${API_URL}/user`;
export const DELETE_USER = `${API_URL}/user`;

export const CONSENT_TO_MFA = `${API_URL}/User/consentToMfa`;
export const RESEND_OTP = `${API_URL}/User/resendOtp`;
export const SUBMIT_OTP = `${API_URL}/User/submitOtp`;

export const GET_QUOTES = `${API_URL}/quote/userQuotes`;
export const GET_QUOTES_BY_SEQUENCE = `${API_URL}/quote`;
export const GET_ESCALATED_QUOTES = `${API_URL}/quote/escalated`;
export const CREATE_QUOTE = `${API_URL}/quote`;
export const UPDATE_QUOTE = `${API_URL}/quote`;
export const DOWNLOAD_QUOTE = `${API_URL}/document`;
export const REVIEW_QUOTE = `${API_URL}/quote/review`;

export const GET_PRICING_SHEETS = `${API_URL}/spreadsheet`;
export const DELETE_PRICING_SHEETS = `${API_URL}/spreadsheet`;
export const EDIT_PRICING_SHEETS = `${API_URL}/spreadsheet`;
export const DOWNLOAD_PRICING_SHEETS = `${API_URL}/spreadsheet`;
export const UPLOAD_PRICING_SHEETS = `${API_URL}/spreadsheet/upload`;
export const GET_ACTIVE_PRICING_SHEETS = `${API_URL}/spreadsheet/active`;

export const GET_REPORT_BY_COMPANY = `${API_URL}/report/companies`;
export const GET_REPORT_BY_BROKERS = `${API_URL}/report/brokers`;
export const GET_REPORT_BY_CLIENTS = `${API_URL}/report/clients`;
export const GET_REPORT_BY_PARAM = `${API_URL}/report/generate`;
export const EXPORT_REPORT_BY_PARAM = `${API_URL}/report/export`;
