import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { getStore } from './app/store';
import messagesEn from './i18n/en.json';
import { baseMuiTheme } from './theme';
import { MuiThemeProvider } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

const messages = {
  en: messagesEn,
};

const language = 'en' || navigator.language.split(/[-_]/)[0];

ReactDOM.render(
  <React.StrictMode>
    <IntlProvider locale={language} messages={messages[language]}>
      <MuiThemeProvider theme={baseMuiTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Provider store={getStore()}>
            <CssBaseline />
            <App />
          </Provider>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </IntlProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
