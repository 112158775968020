import { combineReducers } from '@reduxjs/toolkit';
import userState from '../slices/user';
import usersState from '../slices/users';
import quotesState from '../slices/quotes';
import reportsState from '../slices/reports';
import pricingSheetsState from '../slices/pricingSheets';

const rootReducer = combineReducers({
  userState,
  usersState,
  quotesState,
  reportsState,
  pricingSheetsState,
});

export default rootReducer;
