import {
  AUTH_USER,
  GET_USER,
  RESET_PASSWORD,
  CREATE_PASSWORD,
  UPDATE_USER,
  LOGOUT,
  CONSENT_TO_MFA,
  RESEND_OTP,
  SUBMIT_OTP,
} from '../endpoints';
import axios from 'axios';

/**
 * Get user
 */
export const getUser = (header) => {
  return axios.get(GET_USER, header);
};

/**
 * Auth user
 */
export const authUser = (header, data) => {
  return axios.post(AUTH_USER, data, header);
};

/**
 * User log out
 */
export const userLogout = (header) => {
  return axios.get(LOGOUT, header);
};

/**
 * Request password reset
 */
export const requestUserPasswordReset = (header, data) => {
  return axios.post(RESET_PASSWORD, data, header);
};

export const savePassword = (header, data) => {
  return axios.post(CREATE_PASSWORD, data, header);
};

/**
 * Update a User
 */
export const updateUser = (header, userData) => {
  return axios.put(`${UPDATE_USER}/${userData.id}`, userData, header);
};

/**
 * Soft delete a User
 */
export const deleteUser = (header, userData) => {
  return axios.delete(`${UPDATE_USER}/${userData.id}`, header);
};

/**
 * Consent MFA
 */
export const consentToMfa = (header, data) => {
  return axios.post(CONSENT_TO_MFA, data, header);
};

/**
 * Resend OTP
 */
export const resendOtp = (header, data) => {
  return axios.post(RESEND_OTP, data, header);
};

/**
 * Submit OTP
 */
export const submitOtp = (header, data) => {
  return axios.post(SUBMIT_OTP, data, header);
};
