import { createSlice } from '@reduxjs/toolkit';
import { getRequestHeader } from '../../utils';

/**
 * QuotesSlice: a slice that manages all quotes
 */

export const initialState = {
  isLoading: false,
  quotes: [],
  escalatedQuotes: [],
  error: null,
  isCreatingQuotes: false,
};

export const quotesSlice = createSlice({
  name: 'quotes',
  initialState,
  reducers: {
    getQuotes: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    getQuotesSuccess: (state, action) => {
      state.isLoading = false;
      state.quotes = action.payload;
      state.error = null;
    },
    getQuotesFailed: (state, action) => {
      state.isLoading = false;
      state.user = null;
      state.error = action.payload;
    },
    getEscalatedQuotes: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    getEscalatedQuotesSuccess: (state, action) => {
      state.isLoading = false;
      state.escalatedQuotes = action.payload;
      state.error = null;
    },
    getEscalatedQuotesFailed: (state, action) => {
      state.isLoading = false;
      state.user = null;
      state.error = action.payload;
    },
    createQuote: (state) => {
      return {
        ...state,
        isCreatingQuotes: true,
        error: null,
      };
    },
    createQuoteSuccess: (state, action) => {
      const newQuotes = action.payload;
      const clientId = newQuotes.id;
      const quotes = clientId
        ? state.quotes.map((quote) => {
            if (quote.id === clientId) {
              return { ...quote, quotes: [...quote.quotes, newQuotes.quotes] };
            } else {
              return quote;
            }
          })
        : [...state.quotes, newQuotes];
      return {
        ...state,
        isCreatingQuotes: false,
        quotes: quotes,
        error: null,
      };
    },
    createQuoteFailed: (state, action) => {
      return {
        ...state,
        isCreatingQuotes: false,
        error: action.payload,
      };
    },
    updateQuote: (state) => {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    },
    updateQuoteSuccess: (state) => {
      return {
        ...state,
        isLoading: false,
        // TODO Quotes
        error: null,
      };
    },
    updateQuoteFailed: (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
    reviewQuote: (state) => {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    },
    reviewQuoteSuccess: (state, action) => {
      const filteredQuotes = state.escalatedQuotes.filter(
        (quote) => quote.id !== action.payload
      );
      return {
        ...state,
        isLoading: false,
        escalatedQuotes: filteredQuotes,
        error: null,
      };
    },
    reviewQuoteFailed: (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
  },
});

const {
  getQuotes,
  getQuotesSuccess,
  getQuotesFailed,
  getEscalatedQuotes,
  getEscalatedQuotesSuccess,
  getEscalatedQuotesFailed,
  createQuote,
  createQuoteSuccess,
  createQuoteFailed,
  updateQuote,
  updateQuoteSuccess,
  updateQuoteFailed,
  reviewQuote,
  reviewQuoteSuccess,
  reviewQuoteFailed,
} = quotesSlice.actions;

export default quotesSlice.reducer;

/**
 * Get all quotes of logged in user
 */
export const getQuotesAsync = () => async (dispatch, getState, api) => {
  try {
    dispatch(getQuotes());
    const response = await api.getQuotes(getRequestHeader());
    if (response.status === 200) {
      dispatch(getQuotesSuccess(response.data));
    }
  } catch (error) {
    dispatch(getQuotesFailed(error));
  }
};

/**
 * Get all escalated quotes
 */
export const getEscalatedQuotesAsync = () => async (
  dispatch,
  getState,
  api
) => {
  try {
    dispatch(getEscalatedQuotes());
    const response = await api.getEscalatedQuotes(getRequestHeader());
    if (response.status === 200) {
      dispatch(getEscalatedQuotesSuccess(response.data));
    }
  } catch (error) {
    dispatch(getEscalatedQuotesFailed(error));
  }
};

/**
 * Async fn to crate a quote
 */
export const createQuoteAsync = (data) => async (dispatch, getState, api) => {
  try {
    dispatch(createQuote());
    const response = await api.createQuote(getRequestHeader(), data);
    if (response.status === 200) {
      dispatch(createQuoteSuccess(response.data));
    }
  } catch (error) {
    dispatch(createQuoteFailed(error));
  }
};

/**
 * Update a quote
 */
export const updateQuoteAsync = (data) => async (dispatch, getState, api) => {
  try {
    dispatch(updateQuote());
    const response = await api.updateQuote(getRequestHeader(), data);
    if (response.status === 200) {
      dispatch(updateQuoteSuccess(response.data));
    }
  } catch (error) {
    dispatch(updateQuoteFailed(error));
  }
};

/**
 * Review a quote
 */
export const reviewQuoteAsync = (data) => async (dispatch, getState, api) => {
  const quoteId = data.id;
  try {
    dispatch(reviewQuote());
    const response = await api.reviewQuote(getRequestHeader(), data);
    if (response.status === 200) {
      dispatch(reviewQuoteSuccess(quoteId));
    }
  } catch (error) {
    dispatch(reviewQuoteFailed(error));
  }
};

// Selectors
export const quotesSelector = (state) => state.quotesState;
