import { USER_ROLES } from '../../constants/user';
import moment from 'moment';

export const validateQuotePayload = (quotePayload, role) => {
  const errorMessageMap = new Map();
  let sequenceWillEscalate = false;
  const escalationMessageMap = new Map();
  const { name, gender, dateOfBirth, quotes } = quotePayload;

  if (!role) {
    return {
      errorMessageMap,
      sequenceWillEscalate,
      escalationMessageMap,
    };
  }

  if (!name) errorMessageMap.set('name', 'Please specify client‘s name');
  if (!gender) errorMessageMap.set('gender', 'Please specify client‘s gender');

  // Date of Birth
  if (!dateOfBirth) {
    errorMessageMap.set('dateOfBirth', 'Please specify client‘s date of birth');
  } else {
    if (moment(dateOfBirth).isAfter(moment())) {
      errorMessageMap.set('dateOfBirth', `The maximum date of birth is today`);
    } else if (
      role !== USER_ROLES.PRICING_TEAM &&
      moment(dateOfBirth).isBefore(moment().subtract(80, 'years'))
    ) {
      // If user is non-pricing team, entering a DoB of 80 yr before will not trigger an error, but will set this quote escalated
      sequenceWillEscalate = true;
      escalationMessageMap.set(
        `dateOfBirth`,
        `The client's date of birth is 80 years ago, this quote sequence will be reviewed`
      );
      // errorMessageMap.set(
      //   'dateOfBirth',
      //   `The minimum date of birth is ${moment()
      //     .subtract(80, 'years')
      //     .format('MM/DD/YYYY')}`
      // );
    }
  }
  quotes.forEach((quote, qIndex) => {
    if (quote.__removed) return;
    // Calculation type
    if (quote.fieldToBeCalculated === '') {
      errorMessageMap.set(
        `quote[${qIndex}].fieldToBeCalculated`,
        `Please specify a calculation method`
      );
    }

    // Issue Date
    if (!quote.issueDate) {
      errorMessageMap.set(
        `quote[${qIndex}].issueDate`,
        `Please specify an issue date`
      );
    } else {
      if (role === USER_ROLES.BROKER) {
        if (
          moment(quote.issueDate).isBefore(moment().subtract(1, 'days'), 'day')
        ) {
          // If user is broker, entering a past date will not trigger an error, but will set this quote escalated
          escalationMessageMap.set(
            `quote[${qIndex}].issueDate`,
            `The issue date is before yesterday, this quote will be reviewed`
          );
        }
        if (moment(quote.issueDate).isAfter(moment().add(1, 'years'))) {
          // If user is non-pricing team, entering a DoB of 80 yr before will not trigger an error, but will set this quote escalated
          escalationMessageMap.set(
            `quote[${qIndex}].issueDate`,
            `The issue date is after 1 year from today, this quote will be reviewed`
          );
        }
      }
    }

    // Premium
    const premiumMin = role === USER_ROLES.PRICING_TEAM ? 0 : 10000;
    const premiumMax = role === USER_ROLES.PRICING_TEAM ? 100000000 : 2000000;
    if (quote.fieldToBeCalculated === 'lastPaymentAmount') {
      if (quote.premium > 100000000) {
        errorMessageMap.set(
          `quote[${qIndex}].premium`,
          `Premium cannot be over $100,000,000`
        );
      } else if (quote.premium < premiumMin || quote.premium > premiumMax) {
        escalationMessageMap.set(
          `quote[${qIndex}].premium`,
          `Premium is not in range of $${premiumMin} and $${premiumMax}, this quote will be reviewed`
        );
        // errorMessageMap.set(
        //   `quote[${qIndex}].premium`,
        //   `Premium must be between $${premiumMin} to $${premiumMax}`
        // );
      }
    }

    if (quote.commission > 100) {
      errorMessageMap.set(
        `quote[${qIndex}].commission`,
        `Commission cannot be over 100%`
      );
    }

    // Quote components
    if (
      !quote.quoteComponents.some(
        (component) => component.annuityType === 'termCertain'
      )
    ) {
      errorMessageMap.set(
        `quote[${qIndex}].missingTermCertain`,
        `Please include at least one “Term Certain” payment stream`
      );
    }
    quote.quoteComponents.forEach((component, cIndex) => {
      if (component.__removed) return;
      // First payment date
      if (
        quote.issueDate &&
        !errorMessageMap.has(`quote[${qIndex}].issueDate`)
      ) {
        if (!component.firstPaymentDate) {
          errorMessageMap.set(
            `quote[${qIndex}].quoteComponents[${cIndex}].firstPaymentDate`,
            `Please specify the payment date`
          );
        } else if (moment(component.firstPaymentDate).date() >= 29) {
          errorMessageMap.set(
            `quote[${qIndex}].quoteComponents[${cIndex}].firstPaymentDate`,
            `The payment date should be between 1st to 28th of each month`
          );
        } else if (
          moment(component.firstPaymentDate).isBefore(moment(quote.issueDate))
        ) {
          errorMessageMap.set(
            `quote[${qIndex}].quoteComponents[${cIndex}].firstPaymentDate`,
            `The payment date should be after the issue date`
          );
        } else {
          if (component.annuityType === 'lumpSum') {
            const minDate = moment(quote.issueDate).add(6, 'months');
            const maxDate = moment.min([
              moment(dateOfBirth).add(105, 'years'),
              moment().add(50, 'years'),
            ]);
            if (moment(component.firstPaymentDate).isBefore(minDate)) {
              escalationMessageMap.set(
                `quote[${qIndex}].quoteComponents[${cIndex}].firstPaymentDate`,
                `The payment date on this lump sum payment stream is within 6 months from the issue date, this quote will be reviewed`
              );
            }
            if (
              moment(component.firstPaymentDate).isAfter(maxDate) &&
              role !== USER_ROLES.PRICING_TEAM
            ) {
              escalationMessageMap.set(
                `quote[${qIndex}].quoteComponents[${cIndex}].firstPaymentDate`,
                `The payment date on this lump sum payment stream is after 
                ${maxDate.format('MMM DD, YYYY')}, 
                this quote will be reviewed`
              );
            }
          }
          if (component.annuityType === 'termCertain') {
            const maxDate = moment().add(10, 'years');
            if (
              role !== USER_ROLES.PRICING_TEAM &&
              moment(component.firstPaymentDate).isAfter(maxDate)
            ) {
              escalationMessageMap.set(
                `quote[${qIndex}].quoteComponents[${cIndex}].firstPaymentDate`,
                `The payment date is after 10 years from the today, this quote will be reviewed`
              );
            }
          }
        }
      }

      // Indexation
      const indexationMax =
        role === USER_ROLES.BROKER
          ? 2
          : role === USER_ROLES.HEAD_OFFICE
          ? 2
          : null;
      if (indexationMax && component.indexation > indexationMax) {
        escalationMessageMap.set(
          `quote[${qIndex}].quoteComponents[${cIndex}].indexation`,
          `The indexation is greater than ${indexationMax}%, this quote will be reviewed`
        );
      }
      if (component.indexation < 0) {
        errorMessageMap.set(
          `quote[${qIndex}].quoteComponents[${cIndex}].indexation`,
          `The indexation should be no less than 0%`
        );
      }

      // Guaranteed period
      // Min: 2 years, 0 months OR 0 for pricing team
      // Max: Min((50 - (First Payment Date - Today), 105 - Client Age) years, 11 months or 100yr 11mo for pricing team
      const clientAgeInMonth = moment().diff(
        moment(quotePayload.dateOfBirth),
        'months'
      );
      let condition1 =
        50 * 12 -
        1 -
        moment(component.firstPaymentDate).diff(moment(), 'months');
      // An edge case that issue date is yesterday or today and first payment date is before tomorrow
      if (
        !moment(quote.issueDate).isAfter() &&
        !moment(component.firstPaymentDate).isAfter()
      ) {
        condition1 = 50 * 12;
      }

      const condition2 =
        105 * 12 -
        clientAgeInMonth -
        moment(component.firstPaymentDate).diff(moment(), 'months');
      const guaranteePeriodMinInMonth =
        role === USER_ROLES.PRICING_TEAM ? 0 : 2 * 12;
      const guaranteePeriodMaxInMonth =
        role === USER_ROLES.PRICING_TEAM
          ? 100 * 12
          : Math.min(condition1, condition2);
      if (
        component.guaranteePeriod < guaranteePeriodMinInMonth ||
        component.guaranteePeriod > guaranteePeriodMaxInMonth
      ) {
        escalationMessageMap.set(
          `quote[${qIndex}].quoteComponents[${cIndex}].guaranteePeriod`,
          `The guarantee period is outside of range of 
          ${guaranteePeriodMinInMonth / 12} years, 0 months to 
          ${Math.floor(guaranteePeriodMaxInMonth / 12)} years 
          ${guaranteePeriodMaxInMonth % 12} months, 
          this quote will be reviewed`
        );
        // If instead max Guarantee Period is based on the client turning 105 before 50 years
        // from today: "The selected guarantee period ends after the client turns 105 years old,
        // this quote will be reviewed"
        if (condition2 < condition1) {
          escalationMessageMap.set(
            `quote[${qIndex}].quoteComponents[${cIndex}].guaranteePeriod`,
            `The selected guarantee period ends after the client turns 105 years old, this quote will be reviewed`
          );
        }
      }
      const freqDenominator =
        component.frequency === 'monthly'
          ? 1
          : component.frequency === 'quarterly'
          ? 3
          : component.frequency === 'semiAnnually'
          ? 6
          : 12;
      if (component.guaranteePeriod % freqDenominator !== 0) {
        errorMessageMap.set(
          `quote[${qIndex}].quoteComponents[${cIndex}].guaranteePeriod`,
          `The selected guarantee period does not align to the payment frequency`
        );
      }

      // Payment amount
      if (quote.fieldToBeCalculated === 'lastPaymentAmount' && cIndex === 0) {
        // If quote is for payment amount, the first payment stream doesn't need payment amount
      } else {
        const paymentAmountMin = role === USER_ROLES.PRICING_TEAM ? 0 : 20;
        const paymentAmountMax =
          role === USER_ROLES.PRICING_TEAM ? null : 500000;
        if (
          component.paymentAmount === '' ||
          component.paymentAmount === null
        ) {
          errorMessageMap.set(
            `quote[${qIndex}].quoteComponents[${cIndex}].paymentAmount`,
            `Please specify the payment amount`
          );
        }
        if (component.paymentAmount < 0) {
          errorMessageMap.set(
            `quote[${qIndex}].quoteComponents[${cIndex}].paymentAmount`,
            `The payment amount should be greater than $0`
          );
        } else if (component.paymentAmount > 100000000) {
          errorMessageMap.set(
            `quote[${qIndex}].quoteComponents[${cIndex}].paymentAmount`,
            `The payment amount cannot be more than $100,000,000`
          );
        } else if (component.paymentAmount < paymentAmountMin) {
          escalationMessageMap.set(
            `quote[${qIndex}].quoteComponents[${cIndex}].paymentAmount`,
            `The payment amount is less than $${paymentAmountMin}, this quote will be reviewed`
          );
          // errorMessageMap.set(
          //   `quote[${qIndex}].quoteComponents[${cIndex}].paymentAmount`,
          //   `The payment amount should be no less than $${paymentAmountMin}`
          // );
        }
        if (paymentAmountMax && component.paymentAmount > paymentAmountMax) {
          escalationMessageMap.set(
            `quote[${qIndex}].quoteComponents[${cIndex}].paymentAmount`,
            `The payment amount is greater than $${paymentAmountMax}, this quote will be reviewed`
          );
          // errorMessageMap.set(
          //   `quote[${qIndex}].quoteComponents[${cIndex}].paymentAmount`,
          //   `The payment amount should be between $${paymentAmountMin} and $${paymentAmountMax}`
          // );
        }
      }
    });
  });
  return {
    errorMessageMap,
    sequenceWillEscalate,
    escalationMessageMap,
  };
};
